import Box from "@mui/material/Box";
import OriginalPrice from "./OriginalPrice";
import PriceValue from "./PriceValue";
import FeesTaxes from "./FeesTaxes";

const PriceBreakdown = ({ children, ...props }) => (
    <Box
        sx={{
            textAlign: 'right'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: .5
            }}
        >
            <OriginalPrice {...props} />
            <PriceValue
                sx={{
                    color: 'var(--color-black)',
                    fontWeight: 600,
                    fontSize: 20,
                    lineHeight: 1.3
                }}
                value={props.priceUSD.amount}
                currency={props.currency}
            />
        </Box>
        {children}
        <FeesTaxes
            {...props.priceUSD}
            currency={props.currency}
        />
    </Box>
);

export default PriceBreakdown;
